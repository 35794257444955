import React, { useState } from "react";
import CampaignDetails from "./CampaignDetails";
import CampaignDashScreen from "./CampaignDashScreen";
import CreateCampaign from "./CreateCampaign";
import CSimpleScreen from "./FilteredScreens/SimpleDashScreen";

const CampaignDashboard = (props) => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("");

  var appPage = "";

  switch (applicationScreen) {
    case "Campaign-Details":
      appPage = (
        <CampaignDetails
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
        />
      );
      break;
    case "Campaign-Home":
      appPage = (
        <CampaignDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
          activeUser={props.activeUser}
        />
      );
      break;
    case "CSimple-Screen":
      appPage = <CSimpleScreen onChange={appScreenChange} />;
      break;
    case "Create-Campaign":
      appPage = (
        <CreateCampaign
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
        />
      );
      break;
    default:
      appPage = (
        <CampaignDashScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserVendor={props.activeUserVendor}
          onChange={appScreenChange}
          activeUser={props.activeUser}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default CampaignDashboard;
