import React, { useState, useEffect } from "react";
import CreateOrder from "./CreateOrder";
import CreateOrderCommercial from "./CreateOrderCommercial";
import DashboardScreen from "./DashboardScreen";
import OrderDetails from "./OrderDetails";
import CommercialOrderDetails from "./CommercialOrderDetails";

const Dashboard = (props) => {
  //
  //Order Navigation//
  function orderScreenChange(newValue) {
    setTimeout(function () {
      setorderScreen(newValue);
    }, 100);
  }
  const [orderScreen, setorderScreen] = useState("Dashboard-Home");
  const [orderID, setorderID] = useState();

  function orderDetailID(newValue) {
    setTimeout(function () {
      setorderID(newValue);
    }, 100);
  }

  var orderPage = "";
  switch (orderScreen) {
    case "Create-Order":
      orderPage = (
        <CreateOrder
          activeSelectedDB={props.activeSelectedDB}
          activeUserEmail={props.activeUserEmail}
          activeUserSupplier={props.activeUserSupplier}
          onChange={orderScreenChange}
          activeUserVendor={props.activeUserVendor}
          activeUser={props.activeUser}
        />
      );
      break;
    case "Create-Order-Commercial":
      orderPage = (
        <CreateOrderCommercial
          activeSelectedDB={props.activeSelectedDB}
          activeUserEmail={props.activeUserEmail}
          activeUserSupplier={props.activeUserSupplier}
          onChange={orderScreenChange}
          activeUserVendor={props.activeUserVendor}
          activeUser={props.activeUser}
        />
      );
      break;
    case "Dashboard-Home":
      orderPage = (
        <DashboardScreen
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          activeUserVendor={props.activeUserVendor}
          activeUserRole={props.activeUserRole}
          activeUserEmail={props.activeUserEmail}
          orderDetailID={orderDetailID}
          onChange={orderScreenChange}
          activeUser={props.activeUser}
        />
      );
      break;
    case "Order-Details":
      orderPage = (
        <OrderDetails
          activeUserSupplier={props.activeUserSupplier}
          orderID={orderID}
          onChange={orderScreenChange}
          activeUser={props.activeUser}
        />
      );
      break;
    case "Commercial-Order-Details":
      orderPage = (
        <CommercialOrderDetails
          activeUserSupplier={props.activeUserSupplier}
          orderID={orderID}
          onChange={orderScreenChange}
          activeUser={props.activeUser}
        />
      );
      break;
    default:
      orderPage = (
        <DashboardScreen
          activeSelectedDB={props.activeSelectedDB}
          activeSelectedDB={props.activeSelectedDB}
          activeUserSupplier={props.activeUserSupplier}
          onChange={orderScreenChange}
          activeUser={props.activeUser}
        />
      );
  }

  return <div class="">{orderPage}</div>;
};

export default Dashboard;
