import React, { useState, useEffect, Fragment } from "react";
import ProductDashItem from "./ProductDashItem";
import { Menu, Transition } from "@headlessui/react";
import { DataGrid } from "@mui/x-data-grid";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ProductDashScreen = (props) => {
  useEffect(() => {
    getAllProducts();
  }, []);
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = null;

  switch (typeof props.activeUserSupplier) {
    case "string":
      var client = new faunadb.Client({
        secret: props.activeUserSupplier,
      });
      break;
    default:
      var client = new faunadb.Client({
        secret: "fnAFYcL3PbAAQBpIb1WcMdVFeiUCT6OhX_tvroWE",
      });
      break;
  }

  const COLLECTION_NAME = "products";
  const [allExistingProducts, setallExistingProducts] = useState("");
  //
  //
  //
  //
  // Pull all Orders
  //
  //
  //
  //

  const getAllProducts = async () => {
    {
      const allProducts = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("product-search-by-vendor"), props.activeUserVendor)
          ),
          q.Lambda((x) => q.Get(x))
        )
      );

      setallExistingProducts(allProducts.data);
    }
  };

  //
  //
  //
  //
  if (Object.keys(allExistingProducts).length === 0) {
    return (
      <main class="animate-fade dashboard flex-1">
        <div className="text-center min-h-screen bg-white px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
          <div class="animate-fade p-4 rounded bg-white p-20 rounded-3xl  ">
            <div class="flex flex-col items-center space-y-2">
              <svg
                aria-hidden="true"
                class="mb-10 w-20 h-20 animate-spin text-white fill-blue-600"
                viewBox="0 0 100 101"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                  fill="currentColor"
                />
                <path
                  d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                  fill="currentFill"
                />
              </svg>
              <h1 class="text-6xl text-tpvBlue font-bold">Loading Products</h1>
              <p class="text-2xl text-gray-700">
                If products do not load please contact system admin.
              </p>
            </div>
          </div>
        </div>
      </main>
    );
  } else
    return (
      <main class="animate-fade products flex-1">
        <div class="lg:border-b lg:border-gray-200 lg:py-4 lg:flex lg:items-center lg:justify-between lg:px-8">
          <div class="hidden lg:flex flex-1 justify-between lg:mx-auto">
            <div class="flex flex-1">
              <label for="search-field" class="sr-only">
                Search
              </label>
              <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                <div
                  class="pointer-events-none absolute inset-y-0 left-0 flex items-center"
                  aria-hidden="true"
                >
                  <svg
                    class="h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  id="search-field"
                  name="search-field"
                  class="block h-full w-full border-transparent py-2 pl-8 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:outline-none focus:ring-0 sm:text-sm"
                  placeholder="Search Products"
                  type="search"
                />
              </div>
            </div>
          </div>

          <div class="hidden mt-4 lg:flex sm:mt-0 sm:ml-4">
            <button
              type="button"
              onClick={() => {
                const newPosition = "Create-Product";
                props.onChange(newPosition);
              }}
              class="order-0 inline-flex items-center rounded-md   bg-tpvRed px-8 py-3 text-sm font-medium text-white hover:bg-red-500 focus:outline-none sm:order-1 sm:ml-3"
            >
              New Product
            </button>
          </div>

          <div class="w-full lg:hidden">
            <button
              type="button"
              onClick={() => {
                const newPosition = "Create-Product";
                props.onChange(newPosition);
              }}
              class="w-full bg-tpvRed py-5 text-xl font-medium text-white hover:opacity-80 focus:outline-none"
            >
              + Add New Product
            </button>
          </div>
        </div>

        <div class="lg:mt-8 mt-4">
          <div class="inline-block min-w-full align-middle">
            <div class="ml-6">
              <span class="inline-flex items-center rounded-md bg-purple-50 px-2 py-1 text-xs font-medium text-purple-700 ring-1 ring-inset ring-purple-700/10">
                {props.activeUser[0].data.vendor.name}
              </span>
              <h2 class="pt-3  pb-5 text-2xl font-semibold text-gray-900">
                Available Products{" "}
                <span class="text-gray-400 italic">
                  {props.activeSelectedDB}
                </span>{" "}
              </h2>
            </div>

            <table class="min-w-full divide-y divide-gray-300 flex lg:px-3">
              <DataGrid
                //
                //
                sx={{
                  ".MuiDataGrid-root": {
                    "&:hover": {
                      bgcolor: "#e8f6fe",
                    },
                  },
                  ".MuiDataGrid-columnHeaders": {
                    background: `linear-gradient(90deg, rgba(255,228,170,0.26094187675070024) 35%, rgba(243,255,171,0.3533788515406162) 100%);`,
                  },
                }}
                columns={[
                  {
                    field: "name",
                    headerName: "Product Name",
                    width: 350,
                    renderCell: (params) => {
                      return (
                        <button
                          class="text-black font-medium hover:opacity-70"
                          type="button"
                          onClick={() => {
                            const newPosition = "Product-Details";
                            props.productDetailID(params.row.productID);
                            props.onChange(newPosition);
                          }}
                        >
                          {" "}
                          <span class="truncate">{params.value}</span>
                        </button>
                      );
                    },
                  },

                  {
                    field: "productID",
                    headerName: "Product No.",
                    width: 200,
                  },

                  {
                    field: "utility",
                    headerName: "Utility",
                    width: 150,
                  },
                  {
                    field: "vendorName",
                    headerName: "Vendor",
                    width: 250,
                  },
                  {
                    field: "script",
                    headerName: "Script",
                    width: 250,
                  },
                ]}
                columnHeaderHeight={80}
                rowHeight={70}
                rows={allExistingProducts.map((post) =>
                  //
                  ({
                    id: post.ref.value.id,
                    name: post.data.name,
                    productID: post.data.productID,
                    script: post.data.script.scriptName,
                    vendorName: post.data.vendor.name,
                    utility: post.data.utility.nickname,
                  })
                )}
                // onRowClick={(param) => handleOnClick(param.row)}
                initialState={{
                  pagination: { paginationModel: { pageSize: 10 } },
                }}
                pageSizeOptions={[10, 25, 50, 100]}
              />
            </table>
          </div>
        </div>
      </main>
    );
};

export default ProductDashScreen;
