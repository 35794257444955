import React, { useState } from "react";
import CreateUtility from "./CreateUtility";
import UtilityDashScreen from "./UtilityDashScreen";
import UtilityDetails from "./UtilityDetails";
import USimpleScreen from "./FilteredScreens/USimpleScreen";
import UDetailsScreen from "./FilteredScreens/UDetailsScreen";

const UtilityDashboard = () => {
  // Navigation/ /
  function appScreenChange(newValue) {
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }
  const [applicationScreen, setapplicationScreen] = useState("");

  const [utilityID, setutilityID] = useState();

  function utilityDetailID(newValue) {
    setTimeout(function () {
      setutilityID(newValue);
    }, 100);
  }

  var appPage = "";
  switch (applicationScreen) {
    case "Create-Utility":
      appPage = <CreateUtility onChange={appScreenChange} />;
      break;

    case "Dashboard-Home":
      appPage = (
        <UtilityDashScreen
          utilityDetailID={utilityDetailID}
          utilityID={utilityID}
          onChange={appScreenChange}
        />
      );
      break;
    case "Utility-Details":
      appPage = (
        <UtilityDetails utilityID={utilityID} onChange={appScreenChange} />
      );
      break;
    case "USimple-Screen":
      appPage = (
        <USimpleScreen utilityID={utilityID} onChange={appScreenChange} />
      );
      break;
    case "UDetails-Screen":
      appPage = (
        <UDetailsScreen utilityID={utilityID} onChange={appScreenChange} />
      );
      break;
    default:
      appPage = (
        <UtilityDashScreen
          utilityDetailID={utilityDetailID}
          utilityID={utilityID}
          onChange={appScreenChange}
        />
      );
  }

  return <div class="">{appPage}</div>;
};

export default UtilityDashboard;
