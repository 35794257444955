import React, { useState, useEffect } from "react";

const OrderSubmittedIVR = (props) => {
  //
  //
  const [IVRconfirmation, setIVRconfirmation] = useState({
    sid: "",
    callInitiated: "",
  });
  //
  //
  const [OrderFound, setOrderFound] = useState("empty");
  const [CallMessage, setCallMessage] = useState("Calling Customer");

  //
  //
  //
  // Fauna Stuff
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  var client = new faunadb.Client({
    secret: props.activeUserSupplier,
  });
  //
  //
  //
  //

  //
  //
  //
  //
  //
  //
  const updateOrderEntry = async (id) => {
    await client.query(
      q.Update(
        q.Select(
          "ref",
          q.Get(q.Match(q.Index("find-enrollment"), props.orderNumber))
        ),
        //
        // User Props.ordernumber to find new enrollment //
        //
        {
          data: {
            IVRcallSid: id,
          },
        }
      )
    );
  };
  //
  //
  //
  //
  //
  //
  //
  // Order Information
  //
  //
  const findOrder = async (id) => {
    {
      const newOrder = await client.query(
        q.Map(
          q.Paginate(q.Match(q.Index("find-order-by-callsid"), id)),
          //
          // Add find-order-by-callsid index to other suppliers //
          //
          q.Lambda((x) => q.Get(x))
        )
      );

      if (typeof newOrder.data[0].data === undefined) {
      } else {
        setOrderFound(newOrder.data[0].data);
      }
    }
  };
  //
  //
  //
  //
  //
  //
  //
  //
  //
  // Start IVR Confirmation
  //
  //
  //
  //
  const customerPhone =
    "+1" + props.customer.verifyPhone.replace(/[^+\d]+/g, "");
  //
  //
  //
  const sendIVR = async () => {
    const IVRtime = new Date().toGMTString();
    let response = await fetch(
      "/.netlify/functions/send_voice",

      {
        method: "POST",
        body: JSON.stringify({
          phone: customerPhone,
          supplier: props.activeUserSupplier,
          supplierName: props.activeSelectedDB.toLowerCase(),
          dwelling: props.dwelling,
        }),
      }
    );
    const IVRresponse = await response.json();
    updateOrderEntry(IVRresponse);

    // setTimeout(function () {
    //   findOrder(IVRresponse);
    // }, 5000);

    setIVRconfirmation({
      ...IVRconfirmation,
      sid: IVRresponse,
      callInitiated: IVRtime,
    });
  };

  //
  //
  //
  // if (OrderFound === "empty") {
  //
  // } else {
  //   setInterval(() => {
  //     findOrder(IVRconfirmation.sid);
  //     setCallMessage(OrderFound.IVRcallStatus);
  //   }, 1000);
  // }
  //
  //
  //
  // Get all Order Numbers, Products//
  useEffect(() => {
    sendIVR();
  }, []);
  // //
  //
  //
  const [noOfRender, setNoOfRender] = useState(1);

  // useEffect(() => {
  //   if (noOfRender === 1) {
  //     //for some cases that I need to skip it twice
  //
  //     setNoOfRender(noOfRender + 1);
  //     sendIVR();
  //   }
  //
  // }, [OrderFound.IVRcallStatus]);

  // useEffect(() => {
  //   if (noOfRender < 1) {
  //     //for some cases that I need to skip it twice
  //
  //     setNoOfRender(noOfRender + 1);
  //     sendIVR();
  //   }
  // setInterval(() => {
  //   findOrder(IVRconfirmation.sid);
  //
  // }, 1000);
  // });

  //
  //
  //
  //

  return (
    <div class="animate-fade bg-[radial-gradient(ellipse_at_bottom,_var(--tw-gradient-stops))] from-sky-300 to-cyan-200 flex items-center justify-center h-screen">
      {props.twilioStatus === 500 ? (
        <div class="animate-fade fail bg-white p-10 lg:p-20 shadow-xl rounded-3xl ">
          <div class="text-red-600 flex flex-col items-center space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              class="animate-pulse mb-5 w-28 h-28"
            >
              <path
                fill-rule="evenodd"
                d="M15.22 3.22a.75.75 0 011.06 0L18 4.94l1.72-1.72a.75.75 0 111.06 1.06L19.06 6l1.72 1.72a.75.75 0 01-1.06 1.06L18 7.06l-1.72 1.72a.75.75 0 11-1.06-1.06L16.94 6l-1.72-1.72a.75.75 0 010-1.06zM1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
                clip-rule="evenodd"
              />
            </svg>

            <h1 class="text-center text-6xl font-bold">Call Failed </h1>
            <p class="max-w-xl text-2xl text-center">
              There has been an error while trying to verify this order, please
              double check the phone number or contact your system admin.
              {props.twilioStatus === "" ? (
                <div>
                  <span class=" text-green-600 text-xs font-medium block mt-3 ">
                    {props.emailConfirmation.response.message}
                  </span>
                </div>
              ) : (
                <div>
                  <span class=" text-green-600 text-xs font-medium block mt-3 ">
                    {props.twilioResponseMessage}
                  </span>
                </div>
              )}
            </p>
            <div class="flex ">
              <div class=" ">
                <a
                  href="/app"
                  class="mt-2 order-0 inline-flex items-center rounded-md bg-red-700 px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                >
                  Retry Verification
                </a>
              </div>
              <div class=" ">
                <a
                  href="/app"
                  class="ml-4 mt-2 order-0 inline-flex items-center rounded-md bg-red-700 px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                >
                  Back To Order Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}

      {props.twilioStatus === 200 ? (
        <div class="animate-fade success bg-white p-10 lg:p-20 shadow-xl rounded-3xl  ">
          <div class="text-emerald-400 flex flex-col items-center space-y-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="animate-bounce mb-3 w-28 h-28"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
              />
            </svg>
            <h1 class="text-center text-emerald-900 text-6xl font-bold">
              Order Verified{" "}
            </h1>{" "}
            <p class="max-w-md text-center text-gray-700 text-2xl">
              Your order has been successfully verified by the customer.
              <div>
                {props.twilioStatus === "" ? (
                  <div>
                    <span class=" text-green-600 text-xs font-medium block mt-3 ">
                      Sent: {props.emailConfirmation.time}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span class=" text-green-600 text-xs font-medium block mt-3 ">
                      Sent: {props.twilioResponseMessage}
                    </span>
                  </div>
                )}
              </div>
            </p>
            <div class=" ">
              <div class=" ">
                <a
                  href="/app"
                  class="mt-2 order-0 inline-flex items-center rounded-md bg-green-700 px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
                >
                  Back To Order Dashboard
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div />
      )}

      <div class="animate-fade p-4 rounded bg-white p-10 lg:p-20 shadow-xl rounded-3xl  ">
        <div class="flex flex-col items-center space-y-2 text-tpvBlue">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="currentColor"
            class="animate-pulse mb-5 w-28 h-28"
          >
            <path
              fill-rule="evenodd"
              d="M15 3.75a.75.75 0 01.75-.75h4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0V5.56l-4.72 4.72a.75.75 0 11-1.06-1.06l4.72-4.72h-2.69a.75.75 0 01-.75-.75z"
              clip-rule="evenodd"
            />
            <path
              fill-rule="evenodd"
              d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
              clip-rule="evenodd"
            />
          </svg>
          <h1 class="text-6xl text-tpvBlue font-bold">IVR Call In Progress</h1>
          <p class="text-2xl text-gray-700">
            Please check your order dashboard for status updates.
          </p>
          <div class=" ">
            <a
              href="/app"
              class="ml-4 mt-4 order-0 inline-flex items-center rounded-md bg-tpvBlue px-8 py-3 text-sm font-medium text-white hover:opacity-80 focus:outline-none sm:order-1"
            >
              Back To Order Dashboard
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OrderSubmittedIVR;
