import React, { useState, Fragment, useEffect } from "react";
import NavLogo from "../images/Wordmark-Logo-Light-Gray.svg";
import MainLogo from "../images/Wordmark-Logo-Blue.svg";
import MobileLogo from "../images/Logo-Main-Alt-Gray.png";
import Dashboard from "../components/Home/Dashboard";
import AgentDashboard from "../components/Home/AgentHome/Dashboard";
import Profile from "../components/Users/ProfileScreen";
//
//////////////////// SUPPLIER ADMIN ////////////////////////////////////////////////
import SupplierAdminDashboard from "../components/Home/SupplierHome/Dashboard";
import SupplierCampaignDashboard from "../components/Campaigns/SupplierCampaign/CampaignDashboard";
import SupplierProductDashboard from "../components/Products/SupplierProduct/ProductDashboard";
import SupplierScriptDashboard from "../components/Scripts/SupplierScript/ScriptDashboard";
import SupplierUserDashboard from "../components/Users/SupplierUser/UserDashboard";
import SupplierReportDashboard from "../components/Reporting/SupplierReport/ReportDashboard";
import SupplierSecurity from "../components/Security/SupplierSecurity/SecuritySettings";
import SupplierCompany from "../components/Company/SupplierCompany/CompanyInfo";
import SupplierVendor from "../components/Company/SupplierCompany/VendorDashboard";
import SupplierUtility from "../components/Utility/SupplierUtility/UtilityDashboard";
///////////////////////////////////////////////////////////////////////////////////
//
import ProductDashboard from "../components/Products/ProductDashboard";
import ReportDashboard from "../components/Reporting/ReportDashboard";
import UserDashboard from "../components/Users/UserDashboard";
import CampaignDashboard from "../components/Campaigns/CampaignDashboard";
import ScriptDashboard from "../components/Scripts/ScriptDashboard";
import VendorDashboard from "../components/Company/VendorDashboard";
import CompanyInfo from "../components/Company/CompanyInfo";
import UtilityDashboard from "../components/Utility/UtilityDashboard";
import SecuritySettings from "../components/Security/SecuritySettings";
import { Helmet } from "react-helmet";
import Favicon from "../images/Favicon.png";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Tab } from "@headlessui/react";
import { Ref } from "faunadb";
import VendorDetails from "../components/Company/VendorDetails";

const AppPage = (props) => {
  //
  //
  ///
  /*Start FaunaDB*/
  /*Imports*/
  //
  //
  const faunadb = require("faunadb");
  const q = faunadb.query;

  const client = new faunadb.Client({
    secret: "fnAFYC386qAAQC4nGVmj_Ct4UQp-My63biKkpviJ",
    //secret: "fnAEzBL0dOACT6zMMFftm4TpL9YnKvLh4_xzIRrZ",/Main-opengate secret/
    //secret: "fnAE3sXC3rACUBmDLHi6mrwIQtHqEtbhmbTQaz6v",/Main-opengate secret/
  });
  //
  //
  //
  //

  const [userCredentials, setuserCredentials] = useState("");
  const [activeUser, setactiveUser] = useState("");
  const [activeSupplier, setactiveSupplier] = useState();
  const [userData, setuserData] = useState("");
  const [userDB, setuserDB] = useState("");
  const [isUserLoggedIn, setisUserLoggedIn] = useState(false);
  const [adminDropdown, setadminDropdown] = useState(true);
  const [adminClose, userClose] = useState(false);
  const [userDropdown, setuserDropdown] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  //
  //
  // Check Access
  //
  //
  useEffect(() => {
    const userAccess = window.localStorage.getItem("USER_CREDENTIALS");
    const userSupplier = window.localStorage.getItem("USER_SUPPLIER");
    const activeDB = window.localStorage.getItem("ACTIVE_SUPPLIER");
    const activeUserData = window.localStorage.getItem("ACTIVE_USER");
    if (userAccess !== "null") {
      console.log("inside function");
      const foundUser = JSON.parse(userAccess);
      const setSupplier = JSON.parse(userSupplier);
      const loggedInUserData = JSON.parse(activeUserData);
      setactiveUser(loggedInUserData);
      const selectedDB = JSON.parse(activeDB);
      setactiveSupplier(setSupplier);
      setuserCredentials(foundUser);
      setuserDB(selectedDB);
    } else window.location.href = `/`;
  }, [userCredentials.secret]);
  //
  //

  //
  //
  var activeUserReference = null;

  /* const userAccessTest = JSON.parse(
    window.localStorage.getItem("USER_CREDENTIALS")
  );
  

  switch (typeof userCredentials.secret) {
    case "string":
      var activeUserReference = userCredentials.instance["@ref"].id;
      break;
    default:
      var activeUserReference = null;
      break;
  }
  
  //
  //
  //erLogout
  //
  //
  //
  /*const getUser = async () => {
    {
      const loggedInUser = await client.query(
        q.Map(
          q.Paginate(
            q.Match(q.Index("users_by_reference"), activeUserReference)
          ),
          q.Lambda((x) => q.Get(x))
        )
      );
      setactiveUser(loggedInUser.data);
    }
  };*/

  //
  //
  // Logout
  const userLogout = () => {
    setuserCredentials("");
    setactiveUser("");
    window.localStorage.clear();
    window.location.href = `/`;
  };
  //
  //
  //
  //
  //Application Navigation//
  function appScreenChange(newValue) {
    setapplicationScreen("");
    setTimeout(function () {
      setapplicationScreen(newValue);
    }, 100);
  }

  const [applicationScreen, setapplicationScreen] = useState("Home");

  //
  //
  //
  var activeUserVendor = null;
  var activeUserRole = null;
  var activeUserEmail = null;
  var activeUserSuppliers = null;
  var activeSelectedDB = null;
  var availableVendors = null;
  //
  switch (Object.keys(activeUser).length === 0) {
    case false:
      activeUserVendor = activeUser.map((post) => {
        return post.data.vendor.openGateVendorID;
      });
      availableVendors = activeUser.map((post) => {
        return post.data.vendor;
      });
      activeUserRole = activeUser.map((post) => {
        return post.data.role;
      });
      activeUserEmail = activeUser.map((post) => {
        return post.data.email;
      });
      activeUserSuppliers = activeUser.map((post) => {
        return post.data.suppliers;
      });
      activeSelectedDB = userDB;
      break;
    default:
      activeUserVendor = null;
      break;
  }

  var appPage = "";
  var supplierAdminPage = "";
  var agentPage = "";

  switch (applicationScreen) {
    case "Home":
      agentPage = (
        <AgentDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          activeUser={activeUser}
          positionChange={appScreenChange}
        />
      );
      supplierAdminPage = (
        <SupplierAdminDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          positionChange={appScreenChange}
        />
      );
      appPage = (
        <Dashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
          appPage={appPage}
          test={"test"}
          activeUser={activeUser}
          positionChange={appScreenChange}
        />
      );
      break;
    case "Profile":
      agentPage = (
        <Profile
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          positionChange={appScreenChange}
        />
      );
      supplierAdminPage = (
        <SupplierAdminDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          positionChange={appScreenChange}
        />
      );
      appPage = (
        <Profile
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          positionChange={appScreenChange}
        />
      );
      break;
    case "Campaigns":
      appPage = (
        <CampaignDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
          activeUserRole={activeUserRole}
          activeUser={activeUser}
        />
      );
      supplierAdminPage = (
        <SupplierCampaignDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
          activeUserRole={activeUserRole}
        />
      );
      break;
    case "Products":
      appPage = (
        <ProductDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
          activeUser={activeUser}
        />
      );
      supplierAdminPage = (
        <SupplierProductDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
        />
      );
      break;
    case "Scripts":
      appPage = (
        <ScriptDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
          activeUser={activeUser}
        />
      );
      supplierAdminPage = (
        <SupplierScriptDashboard
          activeSelectedDB={userDB}
          activeUserVendor={activeUserVendor}
          activeUserSupplier={activeSupplier}
        />
      );
      break;
    case "Users":
      appPage = (
        <UserDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          supplierAccess={activeUserSuppliers}
          vendorAccess={availableVendors}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          activeUser={activeUser}
        />
      );
      supplierAdminPage = (
        <SupplierUserDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          supplierAccess={activeUserSuppliers}
          vendorAccess={availableVendors}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
        />
      );
      break;

    case "Security":
      appPage = <SecuritySettings activeUser={activeUser} />;
      supplierAdminPage = <SecuritySettings activeUser={activeUser} />;

      break;
    case "Reporting":
      appPage = (
        <ReportDashboard
          activeSelectedDB={userDB}
          activeUser={activeUser}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
        />
      );

      supplierAdminPage = (
        <SupplierReportDashboard
          activeSelectedDB={userDB}
          activeUser={activeUser}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
        />
      );
      break;
    case "Company":
      appPage = <CompanyInfo positionChange={appScreenChange} />;
      supplierAdminPage = (
        <SupplierCompany
          positionChange={appScreenChange}
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUser={activeUser}
        />
      );
      break;
    case "Vendor":
      appPage = (
        <VendorDetails
          activeSelectedDB={userDB}
          activeUser={activeUser}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
          positionChange={appScreenChange}
        />
      );
      supplierAdminPage = (
        <SupplierVendor
          activeSelectedDB={userDB}
          activeUser={activeUser}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
          positionChange={appScreenChange}
        />
      );
      break;
    case "Utility":
      appPage = <UtilityDashboard activeUser={activeUser} />;
      supplierAdminPage = (
        <SupplierUtility
          positionChange={appScreenChange}
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
        />
      );
      break;
    default:
      agentPage = (
        <AgentDashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserRole={activeUserRole}
          activeUserEmail={activeUserEmail}
          appPage={appPage}
          activeUser={activeUser}
          positionChange={appScreenChange}
        />
      );
      appPage = (
        <Dashboard
          activeSelectedDB={userDB}
          activeUserSupplier={activeSupplier}
          activeUserVendor={activeUserVendor}
          activeUserEmail={activeUserEmail}
          activeUserRole={activeUserRole}
          appPage={appPage}
          test={"test"}
          positionChange={appScreenChange}
          activeUser={activeUser}
        />
      );
      break;
  }

  console.log(7 < 7 + "number test");

  if (Object.keys(activeUser).length === 0) {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title>OpenGate | Third Party Verification</title>

          <meta
            name="description"
            content="Your new moden third party verification option for all your needs."
          />
          <meta property="og:locale" content="en_US"></meta>
          <meta property="og:type" content="website"></meta>
          <meta
            property="og:title"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta
            property="og:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            property="og:site_name"
            content=" OpenGate | Third Party Verification"
          ></meta>
          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta
            name="twitter:description"
            content="Your new moden third party verification option for all your needs."
          ></meta>
          <meta
            name="twitter:title"
            content="OpenGate | Third Party Verification"
          ></meta>
          <link rel="icon" href={Favicon} />
        </Helmet>

        <div class="hidden animate-slow h-full bg-gray-200 md:h-screen">
          <div class="min-h-full px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
            <main class="bg-white mx-auto flex max-w-3xl rounded-xl flex-grow flex-col justify-center px-9 pt-9">
              <div class="flex flex-shrink-0 justify-center">
                <a href="/" class="inline-flex">
                  <img class="w-full mx-auto" src={MainLogo} alt="" />
                </a>
              </div>
              <div class="pt-10 pb-10">
                <div class="text-center">
                  <h1 class="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                    Please log in
                  </h1>
                  <p class="mt-2 text-base text-gray-500">
                    You do not have access to view this page.
                  </p>
                  <div class="mt-6">
                    <a
                      href="/"
                      class="text-base font-medium text-blue-700 hover:text-blue-500"
                    >
                      Return home
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                  </div>
                </div>
              </div>
            </main>
            <footer class="mx-auto w-full max-w-7xl flex-shrink-0 px-4 sm:px-6 lg:px-8">
              <nav class="flex justify-center space-x-4">
                <a
                  href="#"
                  class="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Contact Support
                </a>
                <span
                  class="inline-block border-l border-gray-300"
                  aria-hidden="true"
                ></span>
                <a
                  href="#"
                  class="text-sm font-medium text-gray-500 hover:text-gray-600"
                >
                  Status
                </a>
              </nav>
            </footer>
          </div>
        </div>
      </>
    );
  } else
    return (
      <>
        <main class="mobile hidden lg:block">
          <Helmet>
            <meta charSet="utf-8" />
            <title>OpenGate | Third Party Verification</title>

            <meta
              name="description"
              content="Your new moden third party verification option for all your needs."
            />
            <meta property="og:locale" content="en_US"></meta>
            <meta property="og:type" content="website"></meta>
            <meta
              property="og:title"
              content=" OpenGate | Third Party Verification"
            ></meta>
            <meta
              property="og:description"
              content="Your new moden third party verification option for all your needs."
            ></meta>
            <meta
              property="og:site_name"
              content=" OpenGate | Third Party Verification"
            ></meta>
            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta
              name="twitter:description"
              content="Your new moden third party verification option for all your needs."
            ></meta>
            <meta
              name="twitter:title"
              content="OpenGate | Third Party Verification"
            ></meta>
            <link rel="icon" href={Favicon} />
          </Helmet>
          <div class="animate-fade min-h-full">
            <div
              class="mobile relative z-40 lg:hidden"
              role="dialog"
              aria-modal="true"
            >
              <div class="mobile hidden fixed inset-0 z-40 ">
                <div class="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                  <div class="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      type="button"
                      class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    >
                      <span class="sr-only">Close sidebar</span>
                      <svg
                        class="h-6 w-6 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        aria-hidden="true"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 18L18 6M6 6l12 12"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class={
                activeUserRole.toString() === "Agent"
                  ? "z-50 main nav  hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200  bg-gray-900 lg:pt-5 lg:pb-4"
                  : "z-50 main nav hidden lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col lg:border-r lg:border-gray-200 lg:bg-tpvBlue lg:pt-5 lg:pb-4"
              }
            >
              <div class="flex flex-shrink-0 items-center px-6">
                <a
                  href="/app"
                  class="w-full text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-70"
                  aria-current="page"
                >
                  <img class="h-14 w-auto" src={NavLogo} alt="OpenGate Logo" />
                </a>
              </div>

              <div class="mt-6 flex h-0 flex-1 flex-col overflow-y-auto">
                {activeUser.map((post) => {
                  return (
                    <div class="relative inline-block px-3 text-left">
                      <div>
                        {userDropdown === false ? (
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => setuserDropdown(!userDropdown)}
                              class="w-full bg-tpvBlue text-white group flex pl-1 pr-2 text-left items-center px-2 py-2 text-sm font-medium rounded-md hover:bg-blue-800 focus:outline-none"
                              id="options-menu-button"
                              aria-expanded="false"
                              aria-haspopup="true"
                            >
                              <span class="flex w-full items-center justify-between">
                                <span class="flex min-w-0 items-center justify-between space-x-3">
                                  {post.data.userImage === "" ? (
                                    <svg
                                      class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                      xmlns="http://www.w3.org/2000/svg"
                                      viewBox="0 0 24 24"
                                      fill="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        fill-rule="evenodd"
                                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                        clip-rule="evenodd"
                                      />
                                    </svg>
                                  ) : (
                                    <img
                                      class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                      src={post.data.userImage}
                                      alt=""
                                    />
                                  )}
                                  <a
                                    data-toggle="tooltip"
                                    title={
                                      post.data.firstName +
                                      " " +
                                      post.data.lastName
                                    }
                                  >
                                    <span class="flex min-w-1 flex-1 flex-col">
                                      <span class="truncate text-sm font-medium text-white">
                                        {post.data.firstName +
                                          " " +
                                          post.data.lastName}
                                      </span>

                                      {activeUserRole.toString() ===
                                      "Vendor-Admin" ? (
                                        <span class="truncate text-xs text-sky-400">
                                          {post.data.vendor.supplierVendorID}{" "}
                                          <span class="text-yellow-300">
                                            - Admin
                                          </span>
                                        </span>
                                      ) : (
                                        <>
                                          {activeUserRole.toString() ===
                                          "Supplier-Admin" ? (
                                            <span class="truncate text-xs text-sky-400">
                                              <span class="text-yellow-300">
                                                Supplier Admin
                                              </span>
                                            </span>
                                          ) : (
                                            <span class="truncate text-xs text-sky-400">
                                              {
                                                post.data.vendor
                                                  .supplierVendorID
                                              }{" "}
                                            </span>
                                          )}
                                        </>
                                      )}
                                    </span>{" "}
                                  </a>
                                </span>
                                <svg
                                  class="ml-4 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                    clip-rule="evenodd"
                                  />
                                </svg>
                              </span>
                            </button>
                          </div>
                        ) : (
                          <div class="w-full text-white hover:text-white bg-blue-800 group text-left items-center pt-2 pb-2 text-sm font-medium rounded-md">
                            <button
                              type="button"
                              onClick={() => setuserDropdown(!userDropdown)}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center pl-1 text-sm font-medium rounded-md"
                            >
                              {" "}
                              <span class="flex min-w-0 items-center justify-between space-x-1">
                                {post.data.userImage === "" ? (
                                  <svg
                                    class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                      clip-rule="evenodd"
                                    />
                                  </svg>
                                ) : (
                                  <img
                                    class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                    src={post.data.userImage}
                                    alt=""
                                  />
                                )}

                                <span class="flex min-w-1  flex-1 flex-col">
                                  <span class="truncate text-sm pl-2.5 font-medium text-white">
                                    {post.data.firstName +
                                      " " +
                                      post.data.lastName}
                                  </span>

                                  {activeUserRole.toString() ===
                                  "Vendor-Admin" ? (
                                    <span class="truncate text-xs text-sky-400">
                                      {post.data.vendor.supplierVendorID}{" "}
                                      <span class="text-yellow-300">
                                        - Admin
                                      </span>
                                    </span>
                                  ) : (
                                    <>
                                      {activeUserRole.toString() ===
                                      "Supplier-Admin" ? (
                                        <span class="-ml-3 text-xs text-sky-400">
                                          <span class="text-yellow-300">
                                            Supplier Admin
                                          </span>
                                        </span>
                                      ) : (
                                        <span class="text-left pl-2.5 truncate text-xs text-sky-400">
                                          {post.data.vendor.supplierVendorID}{" "}
                                        </span>
                                      )}
                                    </>
                                  )}
                                </span>
                              </span>
                            </button>

                            <Tab.Group>
                              <Tab.List>
                                <Tab as={Fragment}>
                                  {({ selected }) => (
                                    <div class="-mt-2">
                                      <button
                                        type="button"
                                        className={
                                          selected
                                            ? "transparent"
                                            : "transparent"
                                        }
                                      ></button>
                                    </div>
                                  )}
                                </Tab>
                                <Tab as={Fragment}>
                                  {({ selected }) => (
                                    <div class="ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                      <button
                                        type="button"
                                        className={
                                          applicationScreen === "Profile"
                                            ? "bg-blue-800 underline font-semibold"
                                            : "bg-blue-800 text-white"
                                        }
                                        onClick={() => {
                                          const newPosition = "Profile";
                                          appScreenChange(newPosition);
                                        }}
                                      >
                                        Your Profile
                                      </button>
                                    </div>
                                  )}
                                </Tab>

                                <Tab as={Fragment}>
                                  {({ selected }) => (
                                    <div class="hidden ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                      <button
                                        type="button"
                                        className={
                                          selected
                                            ? "bg-blue-800 underline font-semibold"
                                            : "bg-blue-800 text-white"
                                        }
                                        onClick={() => {
                                          const newPosition = "/";
                                          appScreenChange(newPosition);
                                        }}
                                      >
                                        Settings
                                      </button>
                                    </div>
                                  )}
                                </Tab>

                                <Tab as={Fragment}>
                                  {({ selected }) => (
                                    <div class="ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                      <button
                                        type="button"
                                        className={
                                          selected
                                            ? "bg-blue-800 underline font-semibold"
                                            : "bg-blue-800 text-white"
                                        }
                                        onClick={() => {
                                          userLogout();
                                        }}
                                      >
                                        Sign out
                                      </button>
                                    </div>
                                  )}
                                </Tab>
                              </Tab.List>
                            </Tab.Group>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
                {
                  //"<!-- Navigation For Vendor Admins & Supplier Admin -->"
                }

                {activeUserRole.toString() === "Vendor-Admin" ||
                activeUserRole.toString() === "Supplier-Admin" ? (
                  <nav class="mt-6 px-3">
                    <div class="space-y-1">
                      {applicationScreen === "Home" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Home";
                                appScreenChange(newPosition);
                              }}
                              class="w-full bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-80"
                              aria-current="page"
                            >
                              <svg
                                class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                />
                              </svg>
                              Home
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Home";

                                appScreenChange(newPosition);
                              }}
                              class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                              aria-current="page"
                            >
                              <svg
                                class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                />
                              </svg>
                              Home
                            </button>
                          </div>
                        </div>
                      )}
                      {applicationScreen === "Campaigns" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Campaigns";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                                />
                              </svg>
                              Campaigns
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Campaigns";

                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                                />
                              </svg>
                              Campaigns
                            </button>
                          </div>
                        </div>
                      )}
                      {applicationScreen === "Products" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Products";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122
                  "
                                />
                              </svg>
                              Products
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Products";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122
                "
                                />
                              </svg>
                              Products
                            </button>
                          </div>
                        </div>
                      )}
                      {applicationScreen === "Scripts" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Scripts";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                              Scripts
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Scripts";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                />
                              </svg>
                              Scripts
                            </button>
                          </div>
                        </div>
                      )}
                      {applicationScreen === "Users" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Users-Home";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                              </svg>
                              Users
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Users";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                />
                              </svg>
                              Users
                            </button>
                          </div>
                        </div>
                      )}
                      {applicationScreen === "Security" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Security";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                />
                              </svg>
                              Security
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Security";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                />
                              </svg>
                              Security
                            </button>
                          </div>
                        </div>
                      )}
                      {adminDropdown === true ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <button
                            type="button"
                            onClick={() => setadminDropdown(!adminDropdown)}
                            class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          >
                            <svg
                              class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                              />
                            </svg>
                            Admin
                            <svg
                              class="ml-2 h-6 w-6 flex-shrink-0 text-white group-hover:text-sky-200"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <div class="w-full text-white hover:text-white bg-blue-800 group  items-center pt-2 pb-2 text-sm font-medium rounded-md">
                          <button
                            type="button"
                            onClick={() => setadminDropdown(!adminDropdown)}
                            class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 text-sm font-medium rounded-md"
                          >
                            <svg
                              class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                              />
                            </svg>
                            Admin
                            <svg
                              class="ml-2 h-6 w-6 flex-shrink-0 text-white group-hover:text-sky-200"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fill-rule="evenodd"
                                d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                clip-rule="evenodd"
                              />
                            </svg>
                          </button>

                          <Tab.Group>
                            <Tab.List>
                              <Tab as={Fragment}>
                                {({ selected }) => (
                                  <div class="-mt-2">
                                    <button
                                      type="button"
                                      className={
                                        selected ? "transparent" : "transparent"
                                      }
                                    ></button>
                                  </div>
                                )}
                              </Tab>
                              <Tab as={Fragment}>
                                {({ selected }) => (
                                  <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                    <button
                                      type="button"
                                      className={
                                        selected
                                          ? "bg-blue-800 underline font-semibold"
                                          : "bg-blue-800 text-white"
                                      }
                                      onClick={() => {
                                        const newPosition = "Company";
                                        appScreenChange(newPosition);
                                      }}
                                    >
                                      Supplier
                                    </button>
                                  </div>
                                )}
                              </Tab>

                              <Tab as={Fragment}>
                                {({ selected }) => (
                                  <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                    <button
                                      type="button"
                                      className={
                                        selected
                                          ? "bg-blue-800 underline font-semibold"
                                          : "bg-blue-800 text-white"
                                      }
                                      onClick={() => {
                                        const newPosition = "Vendor";
                                        appScreenChange(newPosition);
                                      }}
                                    >
                                      Vendors
                                    </button>
                                  </div>
                                )}
                              </Tab>

                              <Tab as={Fragment}>
                                {({ selected }) => (
                                  <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                    <button
                                      type="button"
                                      className={
                                        selected
                                          ? "bg-blue-800 underline font-semibold"
                                          : "bg-blue-800 text-white"
                                      }
                                      onClick={() => {
                                        const newPosition = "Utility";
                                        appScreenChange(newPosition);
                                      }}
                                    >
                                      Utilities
                                    </button>
                                  </div>
                                )}
                              </Tab>
                            </Tab.List>
                          </Tab.Group>
                        </div>
                      )}
                      {applicationScreen === "Reporting" ? (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Reporting";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                />
                              </svg>
                              Reporting
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <button
                              type="button"
                              onClick={() => {
                                const newPosition = "Reporting";
                                appScreenChange(newPosition);
                              }}
                              class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                            >
                              <svg
                                class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                aria-hidden="true"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                />
                              </svg>
                              Reporting
                            </button>
                          </div>
                        </div>
                      )}{" "}
                    </div>
                    <div class="mt-8">
                      <h3
                        class="px-3 text-sm font-medium text-gray-50"
                        id="desktop-teams-headline"
                      >
                        Misc
                      </h3>
                      <div
                        class="mt-1 space-y-1"
                        role="group"
                        aria-labelledby="desktop-teams-headline"
                      >
                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <a
                              href="#"
                              class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                            >
                              <span
                                class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                                aria-hidden="true"
                              ></span>
                              <span class="truncate">About</span>
                            </a>
                          </div>
                        </div>

                        <div onClick={() => setuserDropdown(!userClose)}>
                          <div onClick={() => setadminDropdown(!adminClose)}>
                            <a
                              href="#"
                              class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                            >
                              <span
                                class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                                aria-hidden="true"
                              ></span>
                              <span class="truncate">Terms & Conditions</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </nav>
                ) : (
                  <div></div>
                )}
                {
                  //"<!-- Navigation For Vendor Managers -->"
                }

                {activeUserRole.toString() === "Manager" ? (
                  <nav class="mt-6 px-3">
                    <div class="space-y-1">
                      {applicationScreen === "Home" ? (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Home";
                            appScreenChange(newPosition);
                          }}
                          class="w-full bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-80"
                          aria-current="page"
                        >
                          <svg
                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          Home
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Home";
                            appScreenChange(newPosition);
                          }}
                          class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          aria-current="page"
                        >
                          <svg
                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          Home
                        </button>
                      )}
                      {applicationScreen === "Users" ? (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Users";
                            appScreenChange(newPosition);
                          }}
                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          <svg
                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                            />
                          </svg>
                          Users
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Users";
                            appScreenChange(newPosition);
                          }}
                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          <svg
                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                            />
                          </svg>
                          Users
                        </button>
                      )}
                      {applicationScreen === "Reporting" ? (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Reporting";
                            appScreenChange(newPosition);
                          }}
                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          <svg
                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                            />
                          </svg>
                          Reporting
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Reporting";
                            appScreenChange(newPosition);
                          }}
                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        >
                          <svg
                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                            />
                          </svg>
                          Reporting
                        </button>
                      )}{" "}
                    </div>
                    <div class="mt-8">
                      <h3
                        class="px-3 text-sm font-medium text-gray-50"
                        id="desktop-teams-headline"
                      >
                        Misc
                      </h3>
                      <div
                        class="mt-1 space-y-1"
                        role="group"
                        aria-labelledby="desktop-teams-headline"
                      >
                        <a
                          href="#"
                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <span
                            class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                            aria-hidden="true"
                          ></span>
                          <span class="truncate">About</span>
                        </a>

                        <a
                          href="#"
                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <span
                            class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                            aria-hidden="true"
                          ></span>
                          <span class="truncate">Terms & Conditions</span>
                        </a>
                      </div>
                    </div>
                  </nav>
                ) : (
                  <div></div>
                )}

                {
                  //"<!-- Navigation For Vendor Agents -->"
                }

                {activeUserRole.toString() === "Agent" ? (
                  <nav class="mt-6 px-3">
                    <div class="space-y-1">
                      {applicationScreen === "Home" ? (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Home";
                            appScreenChange(newPosition);
                          }}
                          class="w-full bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-80 hover:opacity-80"
                          aria-current="page"
                        >
                          <svg
                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          Home
                        </button>
                      ) : (
                        <button
                          type="button"
                          onClick={() => {
                            const newPosition = "Home";
                            appScreenChange(newPosition);
                          }}
                          class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                          aria-current="page"
                        >
                          <svg
                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="currentColor"
                            aria-hidden="true"
                          >
                            <path
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                            />
                          </svg>
                          Home
                        </button>
                      )}
                    </div>
                    <div class="mt-8">
                      <h3
                        class="px-3 text-sm font-medium text-gray-50"
                        id="desktop-teams-headline"
                      >
                        Misc
                      </h3>
                      <div
                        class="mt-1 space-y-1"
                        role="group"
                        aria-labelledby="desktop-teams-headline"
                      >
                        <a
                          href="#"
                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <span
                            class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                            aria-hidden="true"
                          ></span>
                          <span class="truncate">About</span>
                        </a>

                        <a
                          href="#"
                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                        >
                          <span
                            class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                            aria-hidden="true"
                          ></span>
                          <span class="truncate">Terms & Conditions</span>
                        </a>
                      </div>
                    </div>
                  </nav>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
            {
              //"<!-- Main column -->"
            }
            <div class="flex flex-col lg:pl-64">
              {
                //"<!-- Current Component On Screen -->"
              }

              {activeUserRole.toString() === "Agent" ? (
                <div class="z-30 lg:block hidden">{agentPage}</div>
              ) : (
                <div class="hidden"></div>
              )}

              {activeUserRole.toString() === "Vendor-Admin" ||
              activeUserRole.toString() === "Manager" ? (
                <div class="z-30 lg:block hidden">{appPage}</div>
              ) : (
                <div class="hidden"></div>
              )}

              {activeUserRole.toString() === "Supplier-Admin" ? (
                <div class="z-30 lg:block hidden">{supplierAdminPage}</div>
              ) : (
                <div class="hidden"></div>
              )}
            </div>
          </div>
        </main>

        <div>
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={setSidebarOpen}>
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-tpvBlue pt-5 pb-4 ">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Close sidebar</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div class="flex flex-shrink-0 items-center px-6">
                      <button
                        type="button"
                        onClick={() => {
                          const newPosition = "Home";
                          appScreenChange(newPosition);
                        }}
                        class="w-full text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-70"
                        aria-current="page"
                      >
                        <img
                          class="h-14 w-auto"
                          src={NavLogo}
                          alt="OpenGate Logo"
                        />
                      </button>
                    </div>
                    <div className="h-0 flex-1 overflow-hidden">
                      <nav className="space-y-1 px-2">
                        {
                          //"<!-- Navigation -->"
                        }
                        <nav class="mt-6 px-3">
                          <div class="space-y-1">
                            {activeUser.map((post) => {
                              //

                              return (
                                <div>
                                  {userDropdown === false ? (
                                    <div
                                      onClick={() =>
                                        setadminDropdown(!adminClose)
                                      }
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setuserDropdown(!userDropdown)
                                        }
                                        class="w-full bg-tpvBlue text-white group flex pl-1 pr-2 text-left items-center px-2 py-2 text-sm font-medium rounded-md hover:bg-blue-800 focus:outline-none"
                                        id="options-menu-button"
                                        aria-expanded="false"
                                        aria-haspopup="true"
                                      >
                                        <span class="flex w-full items-center justify-between">
                                          <span class="flex min-w-0 items-center justify-between space-x-3">
                                            {post.data.userImage === "" ? (
                                              <svg
                                                class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                fill="currentColor"
                                                aria-hidden="true"
                                              >
                                                <path
                                                  fill-rule="evenodd"
                                                  d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                                  clip-rule="evenodd"
                                                />
                                              </svg>
                                            ) : (
                                              <img
                                                class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                                src={post.data.userImage}
                                                alt=""
                                              />
                                            )}
                                            <a
                                              data-toggle="tooltip"
                                              title={
                                                post.data.firstName +
                                                " " +
                                                post.data.lastName
                                              }
                                            >
                                              <span class="flex min-w-1 flex-1 flex-col">
                                                <span class="truncate text-sm font-medium text-white pl-0.5">
                                                  {post.data.firstName +
                                                    " " +
                                                    post.data.lastName +
                                                    "."}
                                                </span>

                                                {activeUserRole.toString() ===
                                                "Vendor-Admin" ? (
                                                  <span class="truncate text-xs text-sky-400">
                                                    {
                                                      post.data.vendor
                                                        .supplierVendorID
                                                    }{" "}
                                                    <span class="text-yellow-300">
                                                      - Admin
                                                    </span>
                                                  </span>
                                                ) : (
                                                  <span class="truncate text-xs text-sky-400">
                                                    {
                                                      post.data.vendor
                                                        .supplierVendorID
                                                    }{" "}
                                                  </span>
                                                )}
                                              </span>{" "}
                                            </a>
                                          </span>
                                          <svg
                                            class="ml-4 h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 20 20"
                                            fill="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              fill-rule="evenodd"
                                              d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                              clip-rule="evenodd"
                                            />
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  ) : (
                                    <div class="w-full text-white hover:text-white bg-blue-800 group text-left items-center pt-2 pb-2 text-sm font-medium rounded-md">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setuserDropdown(!userDropdown)
                                        }
                                        class="w-full text-white hover:text-white bg-blue-800 group flex items-center pl-1 text-sm font-medium rounded-md"
                                      >
                                        <span class="flex min-w-0 items-center justify-between space-x-1">
                                          {post.data.userImage === "" ? (
                                            <svg
                                              class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 24 24"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fill-rule="evenodd"
                                                d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                                                clip-rule="evenodd"
                                              />
                                            </svg>
                                          ) : (
                                            <img
                                              class="ml-2 h-12 w-12 flex-shrink-0 rounded-full bg-gray-300"
                                              src={post.data.userImage}
                                              alt=""
                                            />
                                          )}

                                          <span class="flex min-w-1  flex-1 flex-col">
                                            <span class="truncate text-sm pl-2.5 font-medium text-white">
                                              {post.data.firstName +
                                                " " +
                                                post.data.lastName +
                                                "."}
                                            </span>

                                            {activeUserRole.toString() ===
                                            "Vendor-Admin" ? (
                                              <span class="text-left truncate text-xs text-sky-300 pl-2">
                                                {
                                                  post.data.vendor
                                                    .supplierVendorID
                                                }{" "}
                                                <span class="text-yellow-300">
                                                  - Admin
                                                </span>
                                              </span>
                                            ) : (
                                              <span class="text-left truncate text-xs text-sky-300 pl-2">
                                                {
                                                  post.data.vendor
                                                    .supplierVendorID
                                                }
                                              </span>
                                            )}
                                          </span>
                                        </span>
                                      </button>

                                      <Tab.Group>
                                        <Tab.List>
                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="-mt-2">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "transparent"
                                                      : "transparent"
                                                  }
                                                ></button>
                                              </div>
                                            )}
                                          </Tab>
                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    const newPosition =
                                                      "Profile";
                                                    appScreenChange(
                                                      newPosition
                                                    );
                                                  }}
                                                >
                                                  Your Profile
                                                </button>
                                              </div>
                                            )}
                                          </Tab>

                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="hidden ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    const newPosition = "/";
                                                    appScreenChange(
                                                      newPosition
                                                    );
                                                  }}
                                                >
                                                  Settings
                                                </button>
                                              </div>
                                            )}
                                          </Tab>

                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="ml-4 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    userLogout();
                                                  }}
                                                >
                                                  Sign out
                                                </button>
                                              </div>
                                            )}
                                          </Tab>
                                        </Tab.List>
                                      </Tab.Group>
                                    </div>
                                  )}
                                </div>
                              );
                            })}
                            {
                              //"<!-- Navigation For Vendor Admins And Supplier Admins -->"
                            }

                            {activeUserRole.toString() === "Vendor-Admin" ? (
                              <nav class="mt-6">
                                <div class="space-y-1">
                                  {applicationScreen === "Home" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Home";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-80"
                                          aria-current="page"
                                        >
                                          <svg
                                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                            />
                                          </svg>
                                          Home
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Home";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                          aria-current="page"
                                        >
                                          <svg
                                            class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                            />
                                          </svg>
                                          Home
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {applicationScreen === "Campaigns" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Campaigns";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                                            />
                                          </svg>
                                          Campaigns
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Campaigns";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                                            />
                                          </svg>
                                          Campaigns
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {applicationScreen === "Products" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Products";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122
                  "
                                            />
                                          </svg>
                                          Products
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Products";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M6 6.878V6a2.25 2.25 0 012.25-2.25h7.5A2.25 2.25 0 0118 6v.878m-12 0c.235-.083.487-.128.75-.128h10.5c.263 0 .515.045.75.128m-12 0A2.25 2.25 0 004.5 9v.878m13.5-3A2.25 2.25 0 0119.5 9v.878m0 0a2.246 2.246 0 00-.75-.128H5.25c-.263 0-.515.045-.75.128m15 0A2.25 2.25 0 0121 12v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6c0-.98.626-1.813 1.5-2.122
                "
                                            />
                                          </svg>
                                          Products
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {applicationScreen === "Scripts" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Scripts";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                            />
                                          </svg>
                                          Scripts
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Scripts";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                                            />
                                          </svg>
                                          Scripts
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {applicationScreen === "Users" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Users";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                            />
                                          </svg>
                                          Users
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Users";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                            />
                                          </svg>
                                          Users
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {applicationScreen === "Security" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Security";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                            />
                                          </svg>
                                          Security
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Security";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M9 12.75L11.25 15 15 9.75m-3-7.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.749c0 5.592 3.824 10.29 9 11.623 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.571-.598-3.751h-.152c-3.196 0-6.1-1.248-8.25-3.285z"
                                            />
                                          </svg>
                                          Security
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {adminDropdown === true ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setadminDropdown(!adminDropdown)
                                        }
                                        class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                      >
                                        <svg
                                          class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                          />
                                        </svg>
                                        Admin
                                        <svg
                                          class="ml-2 h-6 w-6 flex-shrink-0 text-white group-hover:text-sky-200"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                      </button>
                                    </div>
                                  ) : (
                                    <div class="w-full text-white hover:text-white bg-blue-800 group  items-center pt-2 pb-2 text-sm font-medium rounded-md">
                                      <button
                                        type="button"
                                        onClick={() =>
                                          setadminDropdown(!adminDropdown)
                                        }
                                        class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 text-sm font-medium rounded-md"
                                      >
                                        <svg
                                          class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                          xmlns="http://www.w3.org/2000/svg"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                          stroke-width="1.5"
                                          stroke="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                                          />
                                        </svg>
                                        Admin
                                        <svg
                                          class="ml-2 h-6 w-6 flex-shrink-0 text-white group-hover:text-sky-200"
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 20 20"
                                          fill="currentColor"
                                          aria-hidden="true"
                                        >
                                          <path
                                            fill-rule="evenodd"
                                            d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
                                            clip-rule="evenodd"
                                          />
                                        </svg>
                                      </button>

                                      <Tab.Group>
                                        <Tab.List>
                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="-mt-2">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "transparent"
                                                      : "transparent"
                                                  }
                                                ></button>
                                              </div>
                                            )}
                                          </Tab>
                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    const newPosition =
                                                      "Company";
                                                    appScreenChange(
                                                      newPosition
                                                    );
                                                  }}
                                                >
                                                  Supplier
                                                </button>
                                              </div>
                                            )}
                                          </Tab>

                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    const newPosition =
                                                      "Vendor";
                                                    appScreenChange(
                                                      newPosition
                                                    );
                                                  }}
                                                >
                                                  Vendors
                                                </button>
                                              </div>
                                            )}
                                          </Tab>

                                          <Tab as={Fragment}>
                                            {({ selected }) => (
                                              <div class="ml-11 block mb-1 font-normal hover:font-medium hover:underline">
                                                <button
                                                  type="button"
                                                  className={
                                                    selected
                                                      ? "bg-blue-800 underline font-semibold"
                                                      : "bg-blue-800 text-white"
                                                  }
                                                  onClick={() => {
                                                    const newPosition =
                                                      "Utility";
                                                    appScreenChange(
                                                      newPosition
                                                    );
                                                  }}
                                                >
                                                  Utilities
                                                </button>
                                              </div>
                                            )}
                                          </Tab>
                                        </Tab.List>
                                      </Tab.Group>
                                    </div>
                                  )}
                                  {applicationScreen === "Reporting" ? (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Reporting";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                            />
                                          </svg>
                                          Reporting
                                        </button>
                                      </div>
                                    </div>
                                  ) : (
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <button
                                          type="button"
                                          onClick={() => {
                                            const newPosition = "Reporting";
                                            appScreenChange(newPosition);
                                          }}
                                          class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                        >
                                          <svg
                                            class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="currentColor"
                                            aria-hidden="true"
                                          >
                                            <path
                                              stroke-linecap="round"
                                              stroke-linejoin="round"
                                              d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                            />
                                          </svg>
                                          Reporting
                                        </button>
                                      </div>
                                    </div>
                                  )}{" "}
                                </div>
                                <div class="mt-8">
                                  <h3
                                    class="px-3 text-sm font-medium text-gray-50"
                                    id="desktop-teams-headline"
                                  >
                                    Misc
                                  </h3>
                                  <div
                                    class="mt-1 space-y-1"
                                    role="group"
                                    aria-labelledby="desktop-teams-headline"
                                  >
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <a
                                          href="#"
                                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                        >
                                          <span
                                            class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                                            aria-hidden="true"
                                          ></span>
                                          <span class="truncate">About</span>
                                        </a>
                                      </div>
                                    </div>
                                    <div
                                      onClick={() =>
                                        setuserDropdown(!userClose)
                                      }
                                    >
                                      <div
                                        onClick={() =>
                                          setadminDropdown(!adminClose)
                                        }
                                      >
                                        <a
                                          href="#"
                                          class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                        >
                                          <span
                                            class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                                            aria-hidden="true"
                                          ></span>
                                          <span class="truncate">
                                            Terms & Conditions
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </nav>
                            ) : (
                              <div></div>
                            )}
                            {
                              //"<!-- Navigation For Vendor Managers -->"
                            }

                            {activeUserRole.toString() === "Manager" ? (
                              <nav class="mt-6 px-3">
                                <div class="space-y-1">
                                  {applicationScreen === "Home" ? (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Home";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md hover:opacity-80"
                                      aria-current="page"
                                    >
                                      <svg
                                        class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                        />
                                      </svg>
                                      Home
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Home";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                      aria-current="page"
                                    >
                                      <svg
                                        class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                        />
                                      </svg>
                                      Home
                                    </button>
                                  )}
                                  {applicationScreen === "Users" ? (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Users";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    >
                                      <svg
                                        class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                        />
                                      </svg>
                                      Users
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Users";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    >
                                      <svg
                                        class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
                                        />
                                      </svg>
                                      Users
                                    </button>
                                  )}
                                  {applicationScreen === "Reporting" ? (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Reporting";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full text-white hover:text-white bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    >
                                      <svg
                                        class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                        />
                                      </svg>
                                      Reporting
                                    </button>
                                  ) : (
                                    <button
                                      type="button"
                                      onClick={() => {
                                        const newPosition = "Reporting";
                                        appScreenChange(newPosition);
                                      }}
                                      class="w-full text-white hover:text-white hover:bg-blue-800 group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    >
                                      <svg
                                        class="text-white group-hover:text-white mr-3 flex-shrink-0 h-6 w-6"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="currentColor"
                                        aria-hidden="true"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                                        />
                                      </svg>
                                      Reporting
                                    </button>
                                  )}{" "}
                                </div>
                                <div class="mt-8">
                                  <h3
                                    class="px-3 text-sm font-medium text-gray-50"
                                    id="desktop-teams-headline"
                                  >
                                    Misc
                                  </h3>
                                  <div
                                    class="mt-1 space-y-1"
                                    role="group"
                                    aria-labelledby="desktop-teams-headline"
                                  >
                                    <a
                                      href="#"
                                      class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                      <span
                                        class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="truncate">About</span>
                                    </a>

                                    <a
                                      href="#"
                                      class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                      <span
                                        class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="truncate">
                                        Terms & Conditions
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </nav>
                            ) : (
                              <div></div>
                            )}

                            {
                              //"<!-- Navigation For Vendor Agents -->"
                            }

                            {activeUserRole.toString() === "Agent" ? (
                              <nav class="mt-6 px-3">
                                <div class="space-y-1">
                                  <a
                                    href="/app"
                                    class="w-full hover:bg-blue-800 text-white group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                                    aria-current="page"
                                  >
                                    <svg
                                      class="text-white mr-3 flex-shrink-0 h-6 w-6"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke-width="1.5"
                                      stroke="currentColor"
                                      aria-hidden="true"
                                    >
                                      <path
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                        d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                                      />
                                    </svg>
                                    Home
                                  </a>
                                </div>
                                <div class="mt-8">
                                  <h3
                                    class="px-3 text-sm font-medium text-gray-50"
                                    id="desktop-teams-headline"
                                  >
                                    Misc
                                  </h3>
                                  <div
                                    class="mt-1 space-y-1"
                                    role="group"
                                    aria-labelledby="desktop-teams-headline"
                                  >
                                    <a
                                      href="#"
                                      class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                      <span
                                        class="w-2.5 h-2.5 mr-4 bg-blue-500 rounded-full"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="truncate">About</span>
                                    </a>

                                    <a
                                      href="#"
                                      class="group flex items-center rounded-md px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 hover:text-gray-900"
                                    >
                                      <span
                                        class="w-2.5 h-2.5 mr-4 bg-green-500 rounded-full"
                                        aria-hidden="true"
                                      ></span>
                                      <span class="truncate">
                                        Terms & Conditions
                                      </span>
                                    </a>
                                  </div>
                                </div>
                              </nav>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        </nav>
                      </nav>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Search / Profile Icon */}

          <div className="flex flex-1 flex-col">
            <div className="lg:hidden sticky top-0 z-10 flex h-16 flex-shrink-0 bg-white">
              <button
                type="button"
                className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-400 "
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex flex-1 justify-end px-4">
                <div className="flex ">
                  <img
                    class="w-44 object-scale-down opacity-75"
                    src={MobileLogo}
                  ></img>
                </div>
              </div>
            </div>

            <main>
              {activeUserRole.toString() === "Agent" ? (
                <div class="z-30 lg:hidden block"> {agentPage}</div>
              ) : (
                <div class="hidden"></div>
              )}

              {activeUserRole.toString() === "Vendor-Admin" ||
              activeUserRole.toString() === "Manager" ? (
                <div class="z-30 lg:hidden block"> {appPage}</div>
              ) : (
                <div class="hidden"></div>
              )}

              {activeUserRole.toString() === "Supplier-Admin" ? (
                <div class="z-30 lg:hidden block">{supplierAdminPage}</div>
              ) : (
                <div class="hidden"></div>
              )}
            </main>
          </div>
        </div>
      </>
    );
};

export default AppPage;
